Ext.define('Voyant.panel.VoyantHeader', {
	extend: 'Ext.Panel',
	mixins: ['Voyant.panel.Panel'],
	alias: 'widget.voyantheader',
    statics: {
    	i18n: {
    	}
    },
    constructor: function(config) {
    	Ext.apply(this, {
    		id: 'voyantheader',
    		title: '',
    		layout : 'auto',
    		html: '<div style="margin-top: 150px;"><div id="logo-container"></div></div>',
    		collapseMode : undefined,
				collapsible: true,
				animCollapse: false,
				titleCollapse: false,
				floatable: false,
				header: true,
				hideCollapseTool: true,
				listeners: {
					collapse: this.onCollapse
				},
				titleAlign: 'center'
    	});
        this.callParent(arguments);

        Ext.applyIf(config, {
					moreTools: ['corpusset'],
					includeTools: {
						save: true,
						plus: true,
						help: true,
					}
        })

    	this.mixins['Voyant.panel.Panel'].constructor.call(this, config);
    },

    onCollapse: function(panel) {
    	// the title may be in flux when collapsing, so call defer setting of title
    	Ext.defer(function() {this.setTitle("<img src='"+this.getBaseUrl()+"resources/images/mmo-title-small.png' style='vertical-align: middle; width: 45px; height: 45px;' alt='Melville's Marginalia Online' /> Melville's Marginalia Online")}, 10, panel)
    }
});
